body {
  background-color: black;
  color: white;
}

* {
  box-sizing: border-box;
}

.results-logo {
  position: absolute;
  top: 40px;
  left: 40px;
}

.App {
  margin: 0 auto;
  max-width: 400px;
  text-align: center;

  .vote-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
}

.App.results {
  max-width: 100%;

  .total-votes {
    position: absolute;
    top: 40px;
    right: 40px;

    font-weight: 700;
    font-style: italic;
    font-size: 32px;
  }

  .vote-card {
    width: auto;

    .vote-label {
      font-size: 3vw;
    }

    .result {
      font-size: 3vw;
    }
  }

  .vote-grid {
    margin-top: 80px;
  }
}

.vote-card {
  width: 165px;
  margin-bottom: 20px;
  cursor: pointer;

  &.highlight {
    border: 3px solid #27AA27;
  }

  .result {
    background-color: rgba(202,202,202,0.75);
    position: absolute;
    padding: 15px 0;
    bottom: 0;
    left: 0;
    width: 100%;
    font-weight: bold;
    color: black;
    font-size: 22px;
  }

  .vote-image-wrap {
    position: relative;
    width: 100%;

    .vote-image {
      width: 100%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      &.notSelected {
        filter: grayscale(100%);
      }
    }
  }

  .vote-label {
    background: white;
    color: black;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 6px 0;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    font-style: italic;
  }
}